/* eslint-disable no-restricted-globals */
import * as React from 'react';

import { Container } from 'react-bootstrap';
import { Layout } from '../components';

const PaymentsPage: React.FunctionComponent = () => {
  var url = 'https://pay.texastaxprotest.com/texastaxprotestweb/payment/portal.aspx';

  React.useEffect(() => {
    if (typeof location !== `undefined`) {
      const params = new URLSearchParams(location.search);
      const cadid = params.get('cadid') || '';
      const serviceyear = params.get('serviceyear') || '';
      url = `${url}?cadid=${cadid}&serviceyear=${serviceyear}&v=${Date.now()}`;

      setTimeout(() => {
        location.href = url;
      }, 2000);
    }
  }, []);

  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <Container className="payments" style={{ height: '80vh' }}>
        <h1>Loading...</h1>
      </Container>
      <Layout.Footer />
    </>
  );
};

export default PaymentsPage;

export const Head = () => <Layout.PageHead subtitle="Loading..." googleNoIndex />;
